export class Menu {
    menus = document.querySelectorAll('[data-menu]');

    start() {
        this.menus.forEach(element => {
            element.addEventListener('click', () => {
                let mainMenuClass: string = element.getAttribute('data-menu-class');
                let mainMenu: any = document.querySelector("nav." + mainMenuClass);
                let action: string = element.getAttribute('data-menu');
                console.log(element, action, mainMenuClass, mainMenu);
                if (action === 'close') {
                    mainMenu.classList.add('closed');
                } else {
                    mainMenu.classList.remove('closed');
                }
            });
        })
    }


}