import { IProject } from "../interfaces/ProjectInterfaces";
import { Carousel } from "./Carousel";

export class Project implements IProject {
    id: string = null;
    title: string = null;
    subtitle?: string = null;
    icon: string = null;
    description?: string = null;
    backgroundImg: string = null;
    db: any = null;

    async load(id: string) {
        let self = this;
        try {

            let doc = await self.db.collection("projects").doc(id).get();

            if (doc.exists) {
                let data = doc.data();
                self.id = doc.id;
                for (let k in data) {
                    // @ts-ignore
                    self[k] = data[k];
                }

                return true;
            }

            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    changeTopBar() {
        let tp = document.querySelector('.top-bar-title');
        if (tp) {
            tp.innerHTML = this.title;
        }

        tp = document.querySelector('.logo-title > .bold');
        if (tp) {
            tp.innerHTML = this.title;
        }
    }

    // addIcon() {
    //     let iconCnt = document.querySelector('[data-aws-project=icon]');
    //     if (this.icon && iconCnt) {
    //         let img = document.createElement("img")
    //         img.setAttribute("src", this.icon);
    //         iconCnt.appendChild(img);
    //     }
    // }

    addDescription() {
        let descCnt = document.querySelector('[data-aws-project=description]');
        if (this.description && descCnt) {
            descCnt.insertAdjacentHTML('beforeend', this.description);
        }
    }

    start(id: string) {
        this.load(id).then((loaded: boolean) => {
            if (loaded) {
                this.changeTopBar();
                //this.addIcon();
                this.addDescription();
                //this.addDescription2();
            }

            // Initiate the carousels
            let carousels = document.querySelectorAll('[data-carousel]');
            if (carousels.length > 0) {
                carousels.forEach((c: HTMLElement) => {
                    let carousel = new Carousel(c);
                    carousel.start();
                });
            }
        });

    }

    constructor(DB: any) {
        this.db = DB;
    }
}