import { IProject } from "../interfaces/ProjectInterfaces";
import * as functions from "../functions";

export class ProjectList {
    db: any = null;
    projects: Array<IProject> = [];
    projectListLink: string = '<a class="proyecto" style="background-image: url(\'{{url}}\');"></a>';

    async load(): Promise<any> {

        try {
            //console.log(this.db);
            // let d = this.db.ref('/projects').once('value').then(function (snapshot) {
            //     var username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
            //     // ...
            // });

            let query = this.db.collection("projects");
            if (functions.isProd()) {
                query = query.where('ready', '==', true);
            }
            query = query.orderBy('order', 'asc');

            let snapshot = await query.get();
            this.projects = snapshot.docs.map((doc: any) => {
                let data = doc.data();
                data.id = doc.id;

                return data;
            });

            return true;
        } catch (e) {
            console.error(e);
            return false;
        }

    }

    displayList() {
        let container = document.querySelector("[data-projectslist]");
        if (container) {
            container.innerHTML = "";
            this.projects.forEach((project: IProject, index: number) => {
                let link = document.createElement('a');
                link.classList.add('proyecto');
                link.setAttribute('style', "background-image: url('" + project.backgroundImg + "')");
                link.setAttribute('href', "/proyecto.html?id=" + project.id);
                let title = document.createElement('div');
                title.classList.add('proyecto-title');
                title.innerHTML = '<p class="title">' + project.title + '</p><p class="subtitle">' + project.subtitle + '</p>';
                link.appendChild(title);
                container.appendChild(link);
            });

            // for all titles hide 
            let projectTitles = document.querySelectorAll(".proyecto .proyecto-title");
            //console.log(projects);
            projectTitles.forEach((title: HTMLElement, index: number) => {
                title.style.bottom = (50 - title.clientHeight) + "px";
            });
        }
    }

    async start() {
        let loaded = await this.load();
        if (loaded) {
            this.displayList();
            return true;
        } else {
            return false;
        }

    }

    constructor(DB: any) {
        this.db = DB;
    }
}