import { Menu } from "./js/classes/Menu";
// @ts-ignore
import * as firebase from 'firebase';
import { ProjectScroller } from "./js/classes/ProjectScroller";
import { ProjectList } from "./js/classes/ProjectList";
import { Project } from "./js/classes/Project";
import {ContactForm} from "./js/classes/ContactForm";
//import {CovidDialog} from "./js/classes/CovidDialog";

const firebaseConfig = {
    apiKey: "AIzaSyAPVSEaARJWQUlKPZHkmDnI27m7gTTzT4g",
    authDomain: "alpha-wave-systems.firebaseapp.com",
    databaseURL: "https://alpha-wave-systems.firebaseio.com",
    projectId: "alpha-wave-systems",
    storageBucket: "alpha-wave-systems.appspot.com",
    messagingSenderId: "732835426514",
    appId: "1:732835426514:web:7cf3ad7fcc1117cca07d0b"
};

firebase.initializeApp(firebaseConfig);

let m = new Menu();
m.start();

// console.log(document.querySelector('#covid-dialog')) ;
// if (document.querySelector('#covid-dialog')) {
//     const covidDialog = new CovidDialog();
//     covidDialog.show()
// }

if (document.querySelector(".landing")) {
    let ps = new ProjectScroller();
    ps.start();
}

if (document.querySelector(".portfolio")) {
    let pl = new ProjectList(firebase.firestore());
    pl.start().catch(console.error);
}

if (document.querySelector(".aws-project")) {
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get('id');

    if (id) {
        let p = new Project(firebase.firestore());
        p.start(id);
    }
}

if (document.querySelector(".contacto")) {
    let cf = new ContactForm();
    cf.start();
}