export class Carousel {
    id: string = "";
    el: HTMLElement = null;
    container: HTMLElement = null;
    items: any = null;
    itemLength: number = 0;
    backBtn: any = null;
    nextBtn: any = null;

    constructor(el: HTMLElement) {
        this.id = "AWS" + Math.floor(Math.random() * 10000);
        this.el = el;
        //this.el.setAttribute('id', this.id);
        this.prepare();
    }

    prepare() {
        let selectorId = "[data-carousel=" + this.id + "]";
        this.el.setAttribute('data-carousel', this.id);
        this.container = document.querySelector(selectorId + " .container");
        this.items = document.querySelectorAll(selectorId + " .container .item");
        if (this.items.length > 0) {
            this.itemLength = this.items[0].clientWidth + 40; // plus 40 pixels of padding
            let btns = document.querySelectorAll(selectorId + " .container [data-scroll]");
            btns.forEach((btn: HTMLElement) => {
                btn.addEventListener("click", this.handleClick.bind(this));
            });
        }


    }

    handleClick(event: any) {
        let action = event.target.getAttribute('data-scroll');
        this.scroll(action);
    };

    scroll(action: string) {
        let scrollAmount = 0;
        let amountToScroll = this.itemLength / 10;
        let self = this;
        
        let slideTimer = setInterval(function () {

            if (action === 'back') {
                // go backward 
                self.container.scrollLeft -= amountToScroll;
            } else if (action === 'next') {
                // go forward
                self.container.scrollLeft += amountToScroll;
            }
            
            scrollAmount += amountToScroll;
            if (scrollAmount >= self.itemLength) {
                window.clearInterval(slideTimer);
            }
        }, 25);
    }

    start() { }


}