import * as functions from "../functions";

export class ProjectScroller {
    projectIndexDisplayed: number = -1;
    arrows: HTMLElement = null;
    projects: any = null;

    updateArrowStatus() {
        console.log('Updating arrow Status');
        this.checkForSections();
        if (this.projectIndexDisplayed === -1) {
            if (functions.isElementInViewport(document.querySelector('footer'))) {
                this.arrows.classList.remove('only-down');
                this.arrows.classList.add('only-up');
            } else {
                this.arrows.classList.add('only-down');
                this.arrows.classList.remove('only-up');
            }
        } else {
            this.arrows.classList.remove('only-down');
            this.arrows.classList.remove('only-up');
        }
    }

    clickScroll(action: string) {
        console.log('Clicked to scroll', action);
        this.checkForSections();
        //naturalScroll = false;
        let scrollBy = 0;
        switch (action) {
            case 'next':
                if (this.projectIndexDisplayed < this.projects.length - 1) {
                    // Go forward
                    console.log('Go to', this.projectIndexDisplayed + 1);
                    let par = this.projects[this.projectIndexDisplayed + 1].parentElement;
                    let parPos = par.getBoundingClientRect();
                    scrollBy = parPos.top;
                    this.arrows.classList.remove('only-down');
                    //projectIndexDisplayed++;
                } else if (this.projectIndexDisplayed + 1 === this.projects.length) {
                    // go to footer
                    console.log('Go to footer');
                    let par = document.querySelector('footer');
                    let parPos = par.getBoundingClientRect();
                    scrollBy = parPos.top;
                    this.arrows.classList.add('only-up');
                }
                break;
            case 'prev':
                if (this.projectIndexDisplayed > 0) {
                    // Go backward
                    console.log('Go to', this.projectIndexDisplayed - 1);
                    let par = this.projects[this.projectIndexDisplayed - 1].parentElement;
                    let parPos = par.getBoundingClientRect();
                    scrollBy = parPos.top;
                    this.arrows.classList.remove('only-up');
                    //projectIndexDisplayed++;
                } else if (this.projectIndexDisplayed <= 0) {
                    // is footer visible
                    if (functions.isElementInViewport(document.querySelector('footer'))) {
                        console.log('Go to last project');
                        let par = this.projects[this.projects.length - 1].parentElement;
                        let parPos = par.getBoundingClientRect();
                        scrollBy = parPos.top;
                        this.arrows.classList.remove('only-up');
                    } else {
                        console.log('Go to Top');
                        scrollBy = document.querySelector('.aws-video-cnt').getBoundingClientRect().top;
                        this.arrows.classList.add('only-down');
                    }
    
                }
                break;
            default:
                console.log('Not supported');
                break;
        }
        window.scrollBy({ top: scrollBy, left: 0, behavior: 'smooth' });
        //naturalScroll = true;
    }
    
    checkForSections() {
        //console.log('Projects', projects);
        // Check which one is in viewport
        this.projectIndexDisplayed = -1; // Assume no project is displayed
        this.projects.forEach((project: any, index: number) => {
            let isInView = functions.isElementInViewport(project);
            //console.log(project, isInView);
            if (isInView) {
                this.projectIndexDisplayed = index;
            }
        });
        console.log('In Viewport', this.projectIndexDisplayed);
    }

    start(){
        let self = this;
        if (this.projects.length > 0) {
            if( this.projects.length > 0 ){  
                setTimeout(() => {
                    console.log('First check after a second');
                    this.updateArrowStatus();
                    this.arrows.classList.remove('no-arrows');
                    let clickers = document.querySelectorAll('[data-project-click]');
                    clickers.forEach((elem: any, index: number) => {
                        elem.onclick = this.clickScroll.bind(self,elem.getAttribute('data-project-click'));
                    });
                    //window.addEventListener('scroll', throttle(checkForSections, 200));
                    functions.scrollStop(this.updateArrowStatus.bind(this));
                }, 1000);
            }
        }
    }

    constructor(){
        this.arrows = document.querySelector('[data-project-arrows]');
        this.projects = document.querySelectorAll('[data-project]');
        this.projectIndexDisplayed = -1;
    }
}