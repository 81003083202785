export class ContactForm {
    start() {
        let btn = document.getElementById("send-button");
        btn.addEventListener("click", () =>  {
            const email = document.getElementById("contact-email");
            const name = document.getElementById("contact-name");
            const phone = document.getElementById("contact-phone");
            const messageNode = document.getElementById("contact-message");
            const errorCnt = document.getElementById("error-cnt");

            //Release errors
            this.removeErrors(errorCnt);

            /* Validations */
            let emailValidation = true;
            let phoneValidation = true;
            let nameValidation = true;

            //Email Validation
            if (email.value.length > 0) {
                if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
                    emailValidation = false;
                    this.addError(errorCnt, "Email no válido")
                }
            } else {
                emailValidation = false;
                this.addError(errorCnt, "Email es requerido")
            }


            //Phone validation
            if (phone.value.length > 0 ) {
                if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phone.value)) {
                    phoneValidation = false;
                    this.addError(errorCnt, "Teléfono no válido")
                }
            }

            //Name validation
            if (name.value.length === 0) {
                nameValidation = false;
                this.addError(errorCnt, "Nombre es requerido")
            }

            if (emailValidation && phoneValidation && nameValidation) {
                const message = `Nombre: ${name.value}%0A
                ${phone.value ? `Teléfono: ${phone.value}%0A`: ''}
                Correo: ${email.value}%0A
                
                Mensaje:%0A
                \t${messageNode.value}`;

                window.location.href = `mailto:support@alphawavesystems.com?subject=Información - Página Web&body=${message}`
            }
          })
    }

    removeErrors(node) {
        while(node.firstChild) {
            node.removeChild(node.firstChild)
        }
    }

    addError(node, msg) {
        const nodeMsg = document.createElement("P");
        nodeMsg.classList.add("gutter-bottom");
        nodeMsg.innerText = msg;
        node.appendChild(nodeMsg);
    }
}